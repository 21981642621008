<template>
  <a
    href="#"
    class="dealer-list__item"
    @click.prevent="onItemClick"
  >
    <strong>{{ dealer.header }}</strong>
    <address>
      <span>{{ streetInfo }}</span><span v-if="dealer.zipCode || dealer.city">,</span>
      {{ dealer.zipCode }} {{ dealer.city }}
    </address>
  </a>
</template>

<script>
export default {
  props: {
    dealer: {
      type: Object,
      required: true
    }
  },
  computed: {
    streetInfo() {
      const name = this.dealer.streetName.trim()
      const number = this.dealer.houseNumber.trim()

      return number.length > 0 ? `${name} ${number}` : name
    }
  },
  methods: {
    onItemClick() {
      this.$emit('itemClick', this.dealer)
    }
  }
}
</script>
