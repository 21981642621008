<template>
  <div
    class="c-carousel-wrapper"
    :class="cssClass"
  >
    <div class="c-carousel__header">
      <div class="c-carousel__header__content">
        <component
          :is="titleTag"
          class="c-carousel__header__title"
        >
          {{ title }}
        </component>
        <a
          v-if="showLink"
          :href="link.url"
          class="c-carousel__header__link"
          @click="onLinkClick"
        >{{ link.text }}</a>
      </div>
      <div
        ref="mySliderControls"
        class="c-carousel__controls"
      >
        <button
          data-controls="prev"
          aria-controls="customize"
          tabindex="-1"
          class="c-carousel__navigation-button c-carousel__navigation-button-prev"
        >
          <Icon
            :icon="iconChevronRight"
            :size="20"
            class="c-carousel__button"
          />
        </button>
        <button
          data-controls="next"
          aria-controls="customize"
          tabindex="-1"
          class="c-carousel__navigation-button c-carousel__navigation-button-next"
        >
          <Icon
            :icon="iconChevronRight"
            :size="20"
            class="c-carousel__button"
          />
        </button>
      </div>
    </div>
    <template
      v-if="slotContent"
    >
      <div
        ref="mySlider"
        class="c-carousel"
        v-html="slotContent"
      />
    </template>
    <div
      v-else
      ref="mySlider"
      class="c-carousel"
    >
      <slot />
    </div>
    <a
      v-if="showLink"
      :href="link.url"
      class="c-carousel__footer__link"
      @click="onLinkClick"
    >
      {{ link.text }}
    </a>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useSlider } from '@/composition/slider'
import Icon from '@/components/Icon.vue'
import BBWButton from '@/components/BBWButton.vue'
import iconChevronRight from '@/assets/img/icons/chevron--right.svg'
import gtmUtil from '@/utils/gtm-util'
import utils from '@/utils/vue-util'

export default {
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      default: null
    },
    titleTag: {
      type: String,
      default: 'h2'
    },
    link: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'quarter'
    },
    slotContent: {
      type: String,
      default: ''
    },
    trackingType: {
      type: String,
      default: ''
    },
    trackNavigation: {
      type: Boolean,
      default: true
    }
  },
  emits: ['ready', 'update', 'slide', 'setOnPause'],
  setup(props, context) {
    let slider = null
    const mySlider = ref(null)
    const mySliderControls = ref(null)
    const isDisabled = ref(false)
    const showLink = ref(false)
    const cssClass = computed(() => [
      `c-carousel-wrapper--${props.type}`,
      (isDisabled.value) ? 'is-disabled' : ''
    ])

    const handleItems = (info) => {
      isDisabled.value = info.slideCount <= info.items
      const itemLimit = props.type === 'grid' ? 0 : 2
      showLink.value = props.link && props.link.text && props.link.url && info.slideCount > itemLimit
    }

    const emitUpdate = (info) => {
      context.emit('update', info)
    }

    const next = () => {
      slider.goTo('next')
    }
    const prev = () => {
      slider.goTo('prev')
    }

    const onLinkClick = (e) => {
      gtmUtil.trackLink(e, e.target, {
        event: 'sliderSeeMoreButton',
        sliderType: props.trackingType,
        sliderTitle: props.title
      })
    }

    onMounted(() => {
      slider = useSlider(props.type, mySlider.value, mySliderControls.value)

      if (!slider) {
        return
      }

      mySlider.value.querySelectorAll('.c-track-link').forEach((link) => {
        link.addEventListener('click', (e) => {
          gtmUtil.trackLink(e, link, {
            event: link.dataset.linkTrackingType,
            sliderType: props.trackingType,
            sliderTitle: props.title,
            sliderItemTitle: link.dataset.title
          })
        })
      })

      if (props.slotContent) {
        utils.mountChildComponents({
          BBWButton
        }, mySlider.value)
      }

      slider.events.on('newBreakpointEnd', (e) => {
        handleItems(e)
        emitUpdate(e)
      })

      slider.events.on('indexChanged', (e) => {
        const direction = (e.index > e.indexCached) ? 'right' : 'left'
        const prevEnabled = e.index > 0
        const nextEnabled = e.displayIndex < e.slideCount
        const info = {
          ...e,
          direction,
          prevEnabled,
          nextEnabled
        }
        context.emit('slide', info)
        context.emit('setOnPause')

        if (props.trackNavigation) {
          gtmUtil.sliderScroll(props.trackingType, props.title, direction)
        }
      })

      const info = slider.getInfo()
      const prevEnabled = info.index > 0
      const nextEnabled = info.displayIndex < info.slideCount
      handleItems(info)

      context.emit('ready', {
        ...info,
        prevEnabled,
        nextEnabled
      })
    })

    return {
      next,
      prev,
      mySlider,
      mySliderControls,
      iconChevronRight,
      isDisabled,
      cssClass,
      showLink,
      onLinkClick
    }
  }
}
</script>
