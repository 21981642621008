import { createApp } from 'vue'
import { Vue3Mq } from 'vue3-mq'
import router from '@/composition/search/searchRouter'

function parseProps(dataset) {
  const props = {}

  Object.keys(dataset).forEach((prop) => {
    const val = dataset[prop]

    if (val === 'true' || val === 'True') {
      props[prop] = true
    } else if (val === 'false' || val === 'False') {
      props[prop] = false
    } else if (Number(val)) {
      props[prop] = Number(val)
    } else if (val.length > 1
      && ((val[0] === '[' && val[val.length - 1] === ']')
        || (val[0] === '{' && val[val.length - 1] === '}'))) {
      props[prop] = JSON.parse(val)
    } else {
      props[prop] = val
    }
  })

  return props
}

export default {
  mountComponents(components, rootElement = document, componentSelector = 'data-vue') {
    if (components) {
      Object.entries(components).forEach(([componentName, component]) => {
        const domElements = Array.from(rootElement.querySelectorAll(`[${componentSelector}="${componentName}"]`))
        if (domElements.length) {
          domElements.forEach((el) => {
            this.mountComponent(el, component)
          })
        }
      })
    }
  },
  mountChildComponents(components, rootElement) {
    this.mountComponents(components, rootElement, 'data-vue-child')
  },
  useVue3MQ(app) {
    app.use(Vue3Mq, {
      breakpoints: {
        mobile: 0,
        tablet: 768,
        desktop: 992,
        wide: 1200,
        extra_wide: 1600
      }
    })
  },
  mountComponent(el, component) {
    const props = parseProps(el.dataset)
    props.slotContent = el.innerHTML
    const app = createApp(component, props)
    this.useVue3MQ(app)
    app.use(router)
    app.mount(el)
  }
}
