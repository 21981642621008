<template>
  <form
    class="info-popup-content"
    @submit.prevent="onSubmit"
  >
    <h3 v-if="model.header">
      {{ model.header }}
    </h3>
    <p v-if="model.description">
      {{ model.description }}
    </p>
    <img
      v-if="model.imageUrl"
      :src="model.imageUrl"
      :alt="model.header"
      width="365"
      height="200"
      class="u-block u-mb--s06"
    >
    <div
      v-if="type === 'Dropdown'"
      class="c-form-row"
    >
      <select v-model="answer">
        <option
          disabled
          hidden
          :value="null"
        >
          {{ model.question.dropdownSelectLabel }}
        </option>
        <option
          v-for="item in model.question.answers"
          :key="item.id"
          :value="item.id"
          v-text="item.text"
        />
      </select>
    </div>

    <div
      v-else
      class="c-form-row"
    >
      <div
        v-for="item in model.question.answers"
        :key="item.id"
      >
        <input
          :id="`info-popop-radio-${item.id}`"
          v-model="answer"
          :value="item.id"
          :type="type === 'Radio' ? 'radio' : 'checkbox'"
        >
        <label :for="`info-popop-radio-${item.id}`">
          {{ item.text }}
        </label>
      </div>
    </div>
    <button
      type="submit"
      class="c-button"
      :disabled="isInvalid || loading"
      @click="$emit('ctaClick')"
      v-text="model.link ? model.link.text : 'Submit'"
    />
  </form>
</template>

<script>
import { ref, watch } from 'vue'
import useInfoPopup from '@/composition/useInfoPopup'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {
      typeName: type
    } = props.model.question
    const loading = ref(false)
    const answer = ref(null)
    const isInvalid = ref(true)
    if (type === 'Multiple') {
      answer.value = []
    }
    const {
      submitSurvey
    } = useInfoPopup()

    watch(answer, () => {
      isInvalid.value = !answer.value || !answer.value.length
    })

    const onSubmit = () => {
      if (isInvalid.value || loading.value) {
        return
      }
      const answerString = type === 'Multiple' ? answer.value.join(',') : answer.value

      loading.value = true
      submitSurvey(answerString)
        .then(() => {
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    return {
      answer,
      isInvalid,
      type,
      loading,

      onSubmit
    }
  }
}
</script>
