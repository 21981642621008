import { reactive, computed } from 'vue'
import api from '@/services/search'

export default (apiUrl) => {
  const state = reactive({
    loading: false,
    term: '',
    apiUrl,
    searchResults: []
  })

  let searchIterator = 0
  let asyncSearchIterator = searchIterator

  const resetQuickSearchResults = () => {
    state.searchResults = []
  }

  async function fetchData() {
    const response = await api.getCompanyAutocomplete(state.term, state.apiUrl)
    asyncSearchIterator += 1

    if (searchIterator === asyncSearchIterator) {
      state.searchResults = response.data.items
    }
  }

  async function search() {
    if (!state.loading && state.term !== '') {
      state.loading = true
      fetchData().then(() => {
        state.loading = false
      })
    }
  }

  const setTerm = (term) => {
    state.term = term
    searchIterator += 1
    search()
  }

  return {
    loading: computed(() => state.loading),
    searchResults: computed(() => state.searchResults),
    term: computed(() => state.term),
    setTerm,
    resetQuickSearchResults,
    search
  }
}
