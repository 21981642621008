<template>
  <div>
    <h3 class="farm-list__heading">
      {{ model.translations.dairyListHeading }}
    </h3>
    <list-paging
      :items="farms"
      map-type="dairy farm map"
    >
      <template #default="slotProps">
        <farm-list-item
          v-for="farm in slotProps.pagedItems"
          :key="farm.id"
          :farm="farm"
          :show-details="false"
          :model="model"
          @itemClick="onListItemClick"
        />
      </template>
    </list-paging>
  </div>
</template>

<script>
import ListPaging from '@/components/blocks/MapListPaging.vue'
import FarmListItem from './FarmListItem.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    FarmListItem,
    ListPaging
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    farms: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedFarm: null,
      displayType: 'map'
    }
  },
  methods: {
    onListItemClick(farm) {
      gtmUtil.trackMapListItemClick(farm.header, 'dairy farm map')
      this.$emit('farmClick', farm)
    }
  }
}
</script>
