<template>
  <label for="sortBy">
    <select
      v-if="sortingOptions.length"
      id="sortBy"
      :value="sorting"
      @change="setSorting($event.target.value)"
    >
      <option
        v-for="option in sortingOptions"
        :key="option.value"
        :value="option.value"
      >
        {{ option.title }}
      </option>
    </select>
  </label>
</template>

<script>
import { computed } from 'vue'
import searchQuery from '@/composition/search/searchQuery'
import searchConfig from '@/composition/search/searchConfig'

export default {
  setup() {
    const { searchResult, setSorting } = searchQuery.searchQueryUse()
    const { searchConfiguration } = searchConfig.searchConfigurationUse()

    const sorting = computed(() => {
      if (searchResult.sorting) {
        return searchResult.sorting
      }

      return searchConfiguration.sortingOptions.find((x) => x.default).value
    })

    return {
      sorting,
      sortingOptions: searchConfiguration.sortingOptions,

      setSorting
    }
  }
}
</script>
