<template>
  <div>
    <map-locator
      :model="model"
      :small-on-mobile="true"
      :items="mapDealers(filteredDealers)"
      :list-mode="isListMode"
      :action="action"
      map-type="dealers map"
    >
      <template #header>
        {{ model.title }}
      </template>

      <template #search>
        <div class="map-locator-block__search">
          <label for="zipcode">
            <input
              v-model="postalSearch"
              class="map-locator-block__search-field"
              type="text"
              name="zipcode"
              :placeholder="model.translations.dealerSearchPlaceholder"
              @input="onSearchInput"
              @focus="resetSearch"
            >
          </label>
          <button
            type="submit"
            class="search-form__submit-button"
            :style="{
              opacity: postalSearch ? 1 : 0.5
            }"
            aria-label="Submit"
          />
        </div>
      </template>

      <template #list="slotProps">
        <dealer-list
          :model="model"
          :dealers="filteredDealers"
          :heading="dealersHeading"
          @exactFilteringChange="onExactFilteringChange"
          @dealerClick="slotProps.selectItem"
        />
      </template>

      <template #details="slotProps">
        <dealer-details
          :model="model"
          :dealer="getSelectedDealer(slotProps.selectedItem)"
        />
      </template>
    </map-locator>
  </div>
</template>

<script>
import axios from 'axios'
import DealerList from './dealers/dealer-list.vue'
import DealerDetails from './dealers/dealer-detailed-info.vue'
import MapLocator from '@/components/blocks/MapLocator.vue'

export default {
  components: {
    MapLocator,
    DealerList,
    DealerDetails
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dealers: [],
      postalSearch: '',
      isListMode: false,
      isExactFiltering: false,
      toggle: false
    }
  },
  computed: {
    filteredIds() {
      return this.filteredDealers.map((d) => d.id)
    },
    filteredDealers() {
      if (this.postalSearch.length < 3) {
        return this.dealers
      }
      if (this.isExactFiltering) {
        return this.dealers.filter(({ zipCode }) => zipCode.startsWith(this.postalSearch))
      }
      return this.dealers.filter(({ zipCode, deliversTo }) => [zipCode, ...deliversTo].some(
        (code) => code.startsWith(this.postalSearch)
      ))
    },
    dealersHeading() {
      if (this.postalSearch) {
        if (this.isExactFiltering) {
          return `${this.model.translations.dealerSearchExactResultsHeading} ${this.postalSearch}`
        }
        return `${this.model.translations.dealerSearchResultsHeading} ${this.postalSearch}`
      }
      return `${this.model.translations.dealerListHeading}`
    },
    action() {
      return this.postalSearch.length > 0 ? 'filter' : 'reset'
    }
  },
  created() {
    this.asyncData()
  },
  methods: {
    async asyncData() {
      const { data } = await this.getDealers()
      this.dealers = data.filter((d) => (d.mapPositionX != null && d.mapPositionY != null))
    },
    mapDealers(data) {
      return data.map(({
        id, header, mapPositionX, mapPositionY
      }) => ({
        id,
        title: header,
        selected: false,
        position: {
          lat: Number.parseFloat(mapPositionX),
          lng: Number.parseFloat(mapPositionY)
        }
      })) || []
    },
    getDealers() {
      return axios.get(`/api/${this.model.language}/dealers?`)
    },
    getSelectedDealer(dealer) {
      const [selected] = this.dealers.filter((item) => item.id == dealer.id)
      return selected
    },
    resetSearch() {
      if (this.postalSearch) {
        this.postalSearch = ''
      }
    },
    onSearchInput() {
      if (!this.isListMode && this.postalSearch.length > 2) {
        this.isListMode = true
      }
    },
    onExactFilteringChange(exactFiltering) {
      this.isExactFiltering = exactFiltering
    }
  }
}
</script>
