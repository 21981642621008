import gtmUtil from '@/utils/gtm-util'

export default () => {
  const navigation = document.querySelector('.js-navigation')
  if (!navigation) {
    return
  }

  navigation.addEventListener('click', (e) => {
    const link = e.target === 'a' ? e.target : e.target.closest('a')
    const trackingData = link.dataset.gtmTracking
    if (trackingData) {
      gtmUtil.trackNavigationLink(JSON.parse(trackingData))
    }
  })
}
