export default () => {
  const buttonClass = 'c-buy-product__static-button'
  const footerClass = 'c-footer'
  const buyProductBlock = document.querySelector('.c-buy-product-block')
  const floatButton = document.querySelector('.c-buy-product__float-button')
  const buttonWrapper = document.querySelector('.c-buy-product-block__button-wrapper')
  const staticButton = document.querySelector(`.${buttonClass}`)
  const footer = document.querySelector(`.${footerClass}`)
  let allowFloat = false
  let footerInView = false
  let allowButtonTypeChange = true

  const handleClick = () => {
    staticButton.click()
  }

  const handleFloat = () => {
    floatButton.classList.remove('is-visible')
    if (allowButtonTypeChange) {
      buttonWrapper.dataset.buttonType = 'normal'
    }

    if (allowFloat && !footerInView) {
      floatButton.classList.add('is-visible')
      if (allowButtonTypeChange) {
        buttonWrapper.dataset.buttonType = 'float'
      }
    }
  }

  const onObserve = (entries) => {
    entries.forEach((entry) => {
      if (entry.target.classList.contains(buttonClass)) {
        allowFloat = entry.boundingClientRect.y < 0 && !entry.isIntersecting
      }
      if (entry.target.classList.contains(footerClass)) {
        footerInView = entry.isIntersecting
      }
    })

    handleFloat()
  }

  const initFloatBuyButton = () => {
    if (buyProductBlock && floatButton && footer) {
      allowButtonTypeChange = buttonWrapper.dataset.buttonType !== 'promo'
      floatButton.addEventListener('click', handleClick)
      const observer = new IntersectionObserver(onObserve, { threshold: [0] })
      observer.observe(footer)
      observer.observe(staticButton)
    }
  }

  return {
    initFloatBuyButton
  }
}
