<template>
  <GoogleMap
    ref="mapRef"
    style="width: 100%; height: 100%"
    class="c-google-map"
    gesture-handling="greedy"
    :api-key="apiKey"
    :styles="styles"
    :map-type-control="false"
    :scale-control="false"
    :fullscreen-control="false"
    :street-view-control="false"
    :zoom-control="false"
  >
    <MarkerCluster
      v-if="mapReady && mapMarkers && mapMarkers.length"
      ref="clusterRef"
      :options="markerClusterOptions"
    >
      <Marker
        v-for="marker in mapMarkers"
        :key="marker.id"
        :ref="el => { markerRefs[marker.id] = el }"
        :options="{
          position: marker.position,
          title: marker.title,
          icon: markerIcon
        }"
        @click="onMarkerClick(marker)"
      />
    </MarkerCluster>
  </GoogleMap>
</template>

<script>
import {
  defineComponent,
  ref,
  watch
} from 'vue'
import { GoogleMap, Marker, MarkerCluster } from 'vue3-google-map'
import {
  API_CONFIG,
  THEME_STYLES,
  MAP_COLORS
} from './map-settings'

export default defineComponent({
  components: { GoogleMap, Marker, MarkerCluster },
  props: {
    markers: {
      type: Array,
      required: false,
      default: () => []
    },
    theme: {
      type: String,
      required: false,
      default: () => 'gray'
    },
    selected: {
      type: [String, Number],
      required: false,
      default: () => ''
    }
  },
  setup(props, context) {
    const mapRef = ref(null)
    const clusterRef = ref(null)
    const mapReady = ref(false)
    const mapMarkers = ref(props.markers)
    const mapStyles = [...THEME_STYLES.default, ...THEME_STYLES[props.theme]]
    const markerClusterOptions = ref(null)
    const mapColors = MAP_COLORS[props.theme]
    const selectedMarkerId = ref(null)
    const markerIcon = ref(null)
    const markerIconSelected = ref(null)
    const markerRefs = ref([])

    const onMarkerClick = (e) => {
      selectedMarkerId.value = e.id
      context.emit('markerClick', e)
    }

    const fitToMap = () => {
      if (mapReady.value && mapMarkers.value) {
        const bounds = new mapRef.value.api.LatLngBounds()
        mapMarkers.value.forEach((m) => bounds.extend(m.position))
        mapRef.value.map.fitBounds(bounds)
      }
    }

    const getMarkerIconUrl = (color, opacity) => {
      const svg = window.btoa(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 110" fill="${color}" fill-opacity="${opacity}">
          <path d="M 11.051 0.147 C 5.973 0.147 0.635 3.923 0.635 10.563 C 0.635 16.943 11.051 27.49 11.051 27.49 C 11.051 27.49 21.468 16.943 21.468 10.563 C 21.468 3.923 16.129 0.147 11.051 0.147 Z M 11.051 14.47 C 8.838 14.47 7.145 12.777 7.145 10.563 C 7.145 8.35 8.838 6.657 11.051 6.657 C 13.265 6.657 14.958 8.35 14.958 10.563 C 14.958 12.777 13.135 14.47 11.051 14.47 Z" />
        </svg>
      `)

      return `data:image/svg+xml;base64,${svg}`
    }

    watch(() => props.selected, (value) => {
      selectedMarkerId.value = value
    })

    watch(() => selectedMarkerId.value, (value, oldValue) => {
      if (value) {
        markerRefs.value[value].marker.setIcon(markerIconSelected.value)
      }
      if (oldValue) {
        markerRefs.value[oldValue].marker.setIcon(markerIcon.value)
      }
    })

    watch(() => props.markers, (markers) => {
      mapMarkers.value = markers
      fitToMap()
    })

    watch(() => mapRef.value?.ready, (ready) => {
      if (!ready) {
        return
      }

      const svg = window.btoa(`
        <svg fill="${mapColors.markerBackgroundColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity="1" r="120" />
        </svg>`)

      markerClusterOptions.value = {
        renderer: {
          render: ({ count, position }) => new mapRef.value.api.Marker({
            position,
            label: {
              text: String(count),
              fontFamily: 'FuturaPlus',
              fontWeight: 'bold',
              color: mapColors.color,
              fontSize: '14px'
            },
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new mapRef.value.api.Size(42, 42)
            },
            zIndex: Number(mapRef.value.api.Marker.MAX_ZINDEX) + count
          })
        }
      }

      markerIcon.value = {
        size: new mapRef.value.api.Size(30, 40),
        url: getMarkerIconUrl(mapColors.markerBackgroundColor, 0.9)
      }

      markerIconSelected.value = {
        size: new mapRef.value.api.Size(45, 60),
        scaledSize: new mapRef.value.api.Size(200, 220),
        url: getMarkerIconUrl(mapColors.markerBackgroundColor, 1)
      }

      mapReady.value = true
      fitToMap()
    })

    return {
      mapRef,
      clusterRef,
      markerRefs,
      mapReady,
      apiKey: API_CONFIG.apiKey,
      styles: mapStyles,
      mapMarkers,
      markerClusterOptions,
      onMarkerClick,
      fitToMap,
      selectedMarkerId,
      markerIcon,
      markerIconSelected
    }
  }
})
</script>
