<template>
  <div
    :id="model.urlSegment"
    :class="cssClass"
    class="c-step-by-step-block"
  >
    <Slider
      ref="slider"
      type="full"
      @ready="onReady"
      @slide="onSlideChange"
      @setOnPause="setOnPause"
    >
      <div
        v-if="firstItem"
        class="c-slide"
      >
        <div class="c-step-by-step">
          <div class="c-step-by-step__content">
            <div
              v-if="totalSlides > 1"
              class="c-step-by-step__controls"
            >
              <button
                data-controls="prev"
                aria-controls="customize"
                tabindex="-1"
                :disabled="prevDisabled"
                class="c-carousel__navigation-button c-carousel__navigation-button-prev"
                @click="onPrev"
              >
                <Icon
                  :icon="iconChevronRight"
                  :size="20"
                  class="c-carousel__button"
                />
              </button>
              <button
                data-controls="next"
                aria-controls="customize"
                tabindex="-1"
                :disabled="nextDisabled"
                class="c-carousel__navigation-button c-carousel__navigation-button-next"
                @click="onNext"
              >
                <Icon
                  :icon="iconChevronRight"
                  :size="20"
                  class="c-carousel__button"
                />
              </button>
            </div>
            <div class="c-step-by-step__text">
              <h6
                class="e-preamble"
                v-html="progressText(firstItem)"
              />
              <h2 v-html="firstItem.title" />
            </div>
          </div>
          <div class="c-step-by-step__visual">
            <div class="c-step-by-step__visual-wrapper">
              <CVideo
                v-if="firstItem && firstItem.video"
                :src="firstItem.video.src"
                :type="firstItem.video.type"
                :poster="getPoster(firstItem)"
                :heading="firstItem.title"
                show-btn-play
                controls
                class="c-step-by-step__video"
              />
              <Picture
                v-else-if="firstItem.image"
                :src="firstItem.image.src"
                :full-width="true"
                class="c-step-by-step__image"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in sliderItems"
        :key="index"
        class="c-slide"
      >
        <div class="c-step-by-step">
          <div class="c-step-by-step__content">
            <div
              v-if="totalSlides > 1"
              class="c-step-by-step__controls"
            >
              <button
                data-controls="prev"
                aria-controls="customize"
                tabindex="-1"
                :disabled="prevDisabled"
                class="c-carousel__navigation-button c-carousel__navigation-button-prev"
                @click="onPrev"
              >
                <Icon
                  :icon="iconChevronRight"
                  :size="20"
                  class="c-carousel__button"
                />
              </button>
              <button
                data-controls="next"
                aria-controls="customize"
                tabindex="-1"
                :disabled="nextDisabled"
                class="c-carousel__navigation-button c-carousel__navigation-button-next"
                @click="onNext"
              >
                <Icon
                  :icon="iconChevronRight"
                  :size="20"
                  class="c-carousel__button"
                />
              </button>
            </div>
            <div class="c-step-by-step__text">
              <h6
                class="e-preamble"
                v-html="progressText(item)"
              />
              <h3 v-html="item.title" />
              <div
                v-if="item.text"
                v-html="item.text"
              />
              <a
                v-if="item.link && item.link.url"
                :href="item.link.url"
                v-bind="item.link.attributes"
                class="e-link--arrow"
              >{{ item.link.text }}</a>
            </div>
          </div>
          <div class="c-step-by-step__visual">
            <div class="c-step-by-step__visual-wrapper">
              <CVideo
                v-if="item && item.video"
                :src="item.video.src"
                :type="item.video.type"
                :poster="getPoster(item)"
                :heading="item.title"
                show-btn-play
                controls
                class="c-step-by-step__video"
              />
              <Picture
                v-else-if="item.image"
                :src="item.image.src"
                :full-width="true"
                class="c-step-by-step__image"
              />
            </div>
          </div>
        </div>
      </div>
    </Slider>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import Picture from '@/components/Picture.vue'
import CVideo from '@/components/Video.vue'
import Slider from '@/components/Slider.vue'
import Icon from '@/components/Icon.vue'
import iconChevronRight from '@/assets/img/icons/chevron--right.svg'

export default {
  components: {
    Picture,
    CVideo,
    Slider,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    sliderType: {
      type: String,
      default: 'quarter'
    }
  },
  setup(props) {
    const currentSlide = ref(null)
    const totalSlides = ref(null)
    const progress = computed(() => `${currentSlide.value}/${totalSlides.value}`)
    const prevDisabled = ref(true)
    const nextDisabled = ref(true)
    const slider = ref(null)

    const cssClass = computed(() => [
      (props.model.theme) ? `u-theme--${props.model.theme}` : '',
      { 'c-step-by-step__right-alignment': props.model.mediaContentAlignment === 'left' }
    ])

    const firstItem = computed(() => (props.model.items && props.model.items.length > 0 && props.model.items[0].isFirstItem
      ? props.model.items[0]
      : null
    ))

    const sliderItems = computed(() => (props.model.items && props.model.items.length > 1 && props.model.items[0].isFirstItem
      ? props.model.items.slice(1, props.model.items.length)
      : props.model.items
    ))

    const onPrev = () => {
      slider.value.prev()
    }

    const onNext = () => {
      slider.value.next()
    }

    const setOnPause = () => {
      slider.value.mySlider.querySelectorAll('video').forEach((vid) => vid.pause())
    }

    const updateNav = (info) => {
      currentSlide.value = info.displayIndex
      prevDisabled.value = !info.prevEnabled
      nextDisabled.value = !info.nextEnabled
    }

    const progressText = (item) => {
      if (item.subtitle) {
        return `${progress.value} - ${item.subtitle}`
      }

      return progress.value
    }

    const getPoster = (item) => (item.image && item.image.src)
        || item.video.poster

    return {
      iconChevronRight,
      slider,
      onPrev,
      onNext,
      progressText,
      prevDisabled,
      nextDisabled,
      onReady: (e) => {
        totalSlides.value = e.slideCount
        updateNav(e)
      },
      onSlideChange: updateNav,
      setOnPause,
      cssClass,
      firstItem,
      sliderItems,
      totalSlides,
      getPoster
    }
  }
}
</script>
