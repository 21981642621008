<template>
  <div
    class="farm-list-item"
    :class="{ 'farm-list-item--details': showDetails }"
  >
    <span
      v-if="!showDetails"
      class="item-link"
      @click="onItemClick"
      @keydown="onItemClick"
    />
    <div class="item-header">
      {{ farm.header }}
    </div>
    <div class="item-address">
      {{ farm.streetName }} {{ farm.houseNumber }}, {{ farm.zipCode }} {{ farm.city }}
    </div>
    <div
      v-if="showDetails"
      class="item-details"
    >
      <div class="item-phone">
        {{ farm.phoneNumber }}
      </div>
      <div
        v-if="farm.description"
        class="item-description"
        v-html="description"
      />

      <Picture
        v-if="farm.imageUrl"
        :src="farm.imageUrl"
        class="item-image"
      />

      <div
        v-if="farm.readMoreStoryUrl"
        class="concept-button__wrapper"
      >
        <a
          :href="farm.readMoreStoryUrl"
          class="c-button-link"
        >
          {{ model.translations.dairyReadMoreButtonText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { formatLineBreaks } from '@/utils/html-util'
import Picture from '@/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    farm: {
      type: Object,
      required: true
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: () => false
    },
    displayType: {
      type: String,
      required: false,
      default: () => ''
    },
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    description() {
      return formatLineBreaks(this.farm.description)
    }
  },
  methods: {
    onItemClick() {
      this.$emit('itemClick', this.farm)
    },
    onItemClose() {
      this.$emit('itemClose')
    }
  }
}
</script>
