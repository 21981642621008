<template>
  <tr v-if="showHeading">
    <td colspan="3">
      <h3 class="c-recipe-ingredients__list__heading u-font-size-large">
        {{ model.name }}
      </h3>
    </td>
  </tr>
  <tr v-else>
    <td colspan="3">
      <hr class="c-recipe-ingredients__list__separator">
    </td>
  </tr>
  <recipe-ingredient-item
    v-for="(ingredient, index) in model.items"
    :key="ingredient.id"
    :model="ingredient"
    :class="{ 'last-row': index + 1 === model.items.length}"
  />
</template>

<script>
import { computed } from 'vue'
import RecipeIngredientItem from '@/components/recipe/RecipeIngredientItem.vue'

export default {
  components: {
    RecipeIngredientItem
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showGroupName: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    return {
      showHeading: computed(() => props.showGroupName && props.model.name)
    }
  }
}
</script>
