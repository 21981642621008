<template>
  <div>
    <h3 class="dealer-list__heading">
      {{ heading }}
    </h3>
    <div
      v-if="!dealers.length"
      class="dealer-list__no-results"
    >
      <strong>{{ model.translations.dealerNoResultsFoundLabel }}</strong>
      <p>{{ model.translations.dealerNoResultsFoundHint }}</p>
    </div>
    <div class="dealer-list__exact-filtering form-checkbox">
      <label for="exact-filtering">
        <input
          id="exact-filtering"
          type="checkbox"
          @change="onExactFilteringChange"
        >
        {{ model.translations.dealerExactFilteringLabel }}
      </label>
    </div>
    <list-paging
      :items="dealers"
      :page-size="pageSize"
      map-type="dealers map"
    >
      <template #default="slotProps">
        <dealer-basic-info
          v-for="dealer in slotProps.pagedItems"
          :key="dealer.id"
          :dealer="dealer"
          @itemClick="onListItemClick"
        />
      </template>
    </list-paging>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMq } from 'vue3-mq'
import ListPaging from '@/components/blocks/MapListPaging.vue'
import DealerBasicInfo from './dealer-basic-info.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    ListPaging,
    DealerBasicInfo
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    dealers: {
      type: Array,
      required: true
    },
    heading: {
      type: String,
      default: ''
    }
  },
  setup() {
    const isMobile = computed(() => useMq().current === 'mobile' || useMq().current === 'tablet')
    const pageSize = computed(() => (isMobile ? 4 : 5))

    return {
      isMobile,
      pageSize
    }
  },
  methods: {
    onListItemClick(dealer) {
      gtmUtil.trackMapListItemClick(dealer.header, 'dealers map')
      this.$emit('dealerClick', dealer)
    },
    onExactFilteringChange({ currentTarget: { checked } }) {
      this.$emit('exactFilteringChange', checked)
    }
  }
}
</script>
