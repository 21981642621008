<template>
  <div>
    <span
      :class="openerClass"
      role="button"
      tabindex="0"
      :data-link-type="linkType"
      @click="openModal"
      @keydown.enter="openModal"
    >
      <CI
        v-if="icoName"
        :icon="icons[icoName]"
        class="c-base-icon"
        size="16"
      />
      {{ openerTitle }}
    </span>
    <Modal
      ref="modal"
      :close-text="closeText"
      :reverse-actions-color="reverseActionsColor"
      :is-full-width-content="isFullWidthContent"
      :is-mobile-fullscreen="isMobileFullscreen"
    >
      <div
        v-if="slotContent"
        v-html="slotContent"
      />
      <slot v-else />
    </Modal>
  </div>
</template>

<script>
import { ref } from 'vue'
import Modal from '@/components/Modal.vue'
import CI from '@/components/CI.vue'
import * as icons from '@/utils/icons'

export default {
  components: {
    Modal,
    CI
  },
  props: {
    slotContent: {
      type: String,
      default: ''
    },
    openerTitle: {
      type: String,
      required: true
    },
    openerClass: {
      type: String,
      default: ''
    },
    linkType: {
      type: String,
      default: ''
    },
    icoName: {
      type: String,
      default: ''
    },
    closeText: {
      type: String,
      default: 'close'
    },
    reverseActionsColor: {
      type: Boolean,
      default: false
    },
    isFullWidthContent: {
      type: Boolean,
      default: false
    },
    isMobileFullscreen: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const modal = ref(null)

    const openModal = () => {
      modal.value.onOpenModal()
    }

    return {
      modal,
      icons,

      openModal
    }
  }
}
</script>
