const scrollToAnchor = (clickedEl, additionalOffset) => {
  const section = document.getElementById(clickedEl.attributes.href.value.substring(1))
  window.scrollTo({
    left: 0,
    top: additionalOffset ? section.offsetTop - additionalOffset : section.offsetTop,
    behavior: 'smooth'
  })
}

export default scrollToAnchor
