<template>
  <div>
    <span class="u-font-size-small u-mb--s02 u-flex u-text-uppercase u-font-heavy u-hidden@desktop">
      {{ translations.filtersText }}
    </span>
    <div
      class="item-filters-wrap"
      @click.stop
    >
      <div class="item-filters-inner">
        <search-filters-flat-categories
          v-if="isMobile"
          :model="categories"
        />
        <search-filters-category
          v-for="(category, index) in categories"
          v-else
          :key="category.slug"
          :category="category"
          :is-expanded-by-default="index < expandedFilters"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useMq } from 'vue3-mq'
import SearchFiltersCategory from '@/components/search/SearchFiltersCategory.vue'
import SearchFiltersFlatCategories from '@/components/search/SearchFiltersFlatCategories.vue'
import searchConfig from '@/composition/search/searchConfig'
import searchQueryState from '@/composition/search/searchQuery'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    SearchFiltersCategory,
    SearchFiltersFlatCategories
  },
  setup() {
    const {
      searchConfiguration,
      baseUrl
    } = searchConfig.searchConfigurationUse()
    const {
      activeCategories,
      addCategory
    } = searchQueryState.searchQueryUse()
    const translations = computed(() => searchConfiguration.translations)
    const isMobile = computed(() => useMq().current === 'mobile' || useMq().current === 'tablet')

    const inactiveFilters = computed(() => searchConfiguration.categories.filter((x) => !activeCategories.value.includes(x)))
    const selectFilter = (filter) => {
      addCategory(filter)
      gtmUtil.addFilter()
    }

    return {
      baseUrl,
      inactiveFilters,
      categories: computed(() => searchConfiguration.categories),
      expandedFilters: 2,
      isMobile,
      translations,

      selectFilter
    }
  }
}
</script>
