<template>
  <div>
    <input
      :id="model.elementGuid"
      type="hidden"
      class="Form__Element FormHidden FormHideInSummarized"
      data-f-type="hidden"
      :name="model.elementName"
      :value="token"
    >
    <div
      :id="recaptchaComponentId"
      data-size="invisible"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import useRecaptcha from '@/composition/useRecaptcha'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const {
      addComponent,
      setSiteKey
    } = useRecaptcha()

    const {
      elementGuid: inputElementId,
      elementName: inputElementName,
      recaptchaKey,
      formGuid
    } = props.model
    const recaptchaComponentId = ref('recaptcha_'.concat(inputElementId))
    const token = ref('')

    const recaptchaSubmitCallback = (tokenValue) => {
      token.value = tokenValue
      const form = document.getElementById(formGuid)
      const submitButton = form.querySelector('[type=submit]')
      submitButton.click()
    }

    addComponent({
      recaptchaElementId: recaptchaComponentId.value,
      inputElementName,
      inputElementId,
      recaptchaSubmitCallback
    })

    onMounted(() => {
      setSiteKey(recaptchaKey)
    })

    return {
      recaptchaComponentId,
      token
    }
  }
}
</script>
