import { tns } from 'tiny-slider-rtl/src/tiny-slider.module'

const getDirection = () => (document.querySelector('[dir]') ? document.querySelector('[dir]').getAttribute('dir') || 'ltr' : 'ltr')

const config = {
  full: {
    mode: 'gallery',
    speed: 600,
    gutter: 0,
    items: 1,
    edgePadding: 0
  },
  half: {
    responsive: {
      768: {
        edgePadding: 0,
        items: 2,
        gutter: 40
      },
      1200: {
        gutter: 80
      }
    }
  },
  third: {
    responsive: {
      500: {
        edgePadding: 0,
        items: 2.2,
        gutter: 16
      },
      768: {
        edgePadding: 0,
        items: 3,
        gutter: 32
      }
    }
  },
  quarter: {
    responsive: {
      768: {
        edgePadding: 0,
        items: 4,
        gutter: 32
      }
    }
  },
  grid: {
    responsive: {
      768: {
        edgePadding: 0,
        items: 1,
        slideBy: 1,
        gutter: 0
      }
    }
  }
}

export function useSlider(sliderType, sliderElement, controlsElement, sliderProps = {}) {
  const typedConfig = config[sliderType] || config.quarter

  const slider = tns({
    container: sliderElement,
    controlsContainer: controlsElement,
    autoWidth: false,
    items: 1.1,
    slideBy: 1,
    edgePadding: 16,
    gutter: 16,
    loop: false,
    rewind: (sliderElement.offsetWidth < 768) || false,
    nav: false,
    mouseDrag: true,
    swipeAngle: false,
    textDirection: getDirection(),
    ...typedConfig,
    ...sliderProps
  })

  return slider
}

export default {}
