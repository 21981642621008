import api from './_config'

const getInfoPopupModel = ({
  popupId,
  hem = '',
  language = null
}) => {
  const params = {
    popupId,
    hem
  }

  return api.get(`/api/${language}/sfmc/popup/`, { params })
}

const submitSurveyApi = ({
  popupId,
  hem,
  language,
  surveyId,
  questionId,
  answerId,
  token
}) => {
  const params = {
    popupId,
    hem,
    surveyId,
    questionId,
    answerId
  }

  const headers = {
    RequestVerificationToken: token
  }

  return api.post(`/api/${language}/sfmc/submit/`, params, { headers })
}

export {
  getInfoPopupModel,
  submitSurveyApi
}
