import Fraction from 'fraction.js'

export function formatFractionNumber(number, language) {
  const roundedNumber = Math.round((number + Number.EPSILON) * 100) / 100
  const fraction = new Fraction(roundedNumber)
  const fractionStr = fraction.toFraction(true)
  if (fractionStr && fractionStr.indexOf('/') > -1) {
    const fractionStrArray = fractionStr.split(' ')
    const fractions = {
      '1/2': '½', '1/3': '⅓', '2/3': '⅔', '1/4': '¼', '3/4': '¾', '1/5': '⅕', '2/5': '⅖', '3/5': '⅗', '4/5': '⅘', '1/6': '⅙', '5/6': '⅚', '1/7': '⅐', '1/8': '⅛', '3/8': '⅜', '5/8': '⅝', '7/8': '⅞', '1/9': '⅑'
    }
    if (fractionStrArray.length > 1) {
      if (fractions[fractionStrArray[1]] !== undefined) {
        return `${fractionStrArray[0]} ${fractions[fractionStrArray[1]]}`
      }
    } else if (fractions[fractionStrArray[0]] !== undefined) {
      return `${fractions[fractionStrArray[0]]}`
    }
  }
  return number.toLocaleString(language, { maximumFractionDigits: 2 })
}

export default {
  formatFractionNumber
}
