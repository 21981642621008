<template>
  <map-locator
    :model="model"
    :small-on-mobile="true"
    :items="mapFarms(farms)"
    action="filter"
    map-type="dairy farm map"
  >
    <template #header>
      {{ model.title }}
    </template>

    <template #list="slotProps">
      <farm-list
        :farms="farms"
        :model="model"
        @farmClick="slotProps.selectItem"
      />
    </template>

    <template #details="slotProps">
      <farm-list-item
        :farm="getSelectedFarm(slotProps.selectedItem)"
        :model="model"
        :display-type="slotProps.displayType"
        :show-details="true"
        class="farm-details"
        @itemClose="onItemClose"
      />
    </template>
  </map-locator>
</template>

<script>
import axios from 'axios'
import MapLocator from './MapLocator.vue'
import FarmList from './dairy-farm/FarmList.vue'
import FarmListItem from './dairy-farm/FarmListItem.vue'

export default {
  components: {
    MapLocator,
    FarmList,
    FarmListItem
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      farms: []
    }
  },
  created() {
    this.asyncData()
  },
  methods: {
    async asyncData() {
      const { data } = await this.getFarms()
      this.farms = data
    },
    mapFarms(data) {
      return data.map(({
        id, header, mapPositionX, mapPositionY
      }) => ({
        id,
        title: header,
        selected: false,
        position: {
          lat: Number.parseFloat(mapPositionX),
          lng: Number.parseFloat(mapPositionY)
        }
      })) || []
    },
    onItemClose() {
      this.selectedFarm = null
    },
    getFarms() {
      return axios.get(`/api/${this.model.language}/farms`)
    },
    getSelectedFarm(farm) {
      const [selected] = this.farms.filter((item) => item.id == farm.id)
      return selected
    }
  }
}
</script>
