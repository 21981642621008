import { reactive } from 'vue'

const searchConfigurationState = reactive({
  searchConfiguration: {},
  initialized: false
})

export default {
  searchConfigurationUse(configuration, baseUrl) {
    const state = searchConfigurationState

    if (configuration && !state.initialized) {
      configuration.categories.forEach((c) => c.items.forEach((i) => { i.isBrand = c.isBrand }))
      state.searchConfiguration = configuration
      state.baseUrl = baseUrl
      state.initialized = true
    }

    // Added check for changing config on different search type
    if (configuration && (configuration.type !== state.searchConfiguration.type)) {
      state.searchConfiguration = configuration
      state.baseUrl = baseUrl
    }

    return {
      searchConfiguration: state.searchConfiguration,
      baseUrl: state.baseUrl
    }
  }
}
