const getFrequencyTime = (value, type) => {
  let multiplier = 1
  switch (type) {
    case ('week'):
      multiplier = 7
      break
    case ('month'):
      multiplier = 31
      break
    case ('debug'):
      multiplier = 1 / (24 * 60 * 60 * 1000)
      break
    default:
      multiplier = 1
  }

  return Math.round(multiplier * 24 * 60 * 60 * 1000)
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getFrequencyTime
}
