<template>
  <div class="info-popup-content">
    <h3
      v-if="model.header"
      class="u-flex"
    >
      <CI
        v-if="model.isError"
        :icon="IconInfo"
        class="c-base-icon u-mr--s02"
        size="20"
      />
      {{ model.header }}
    </h3>
    <p v-if="model.description">
      {{ model.description }}
    </p>
    <img
      v-if="model.imageUrl"
      :src="model.imageUrl"
      :alt="model.header"
      width="365"
      height="200"
      class="u-block"
      :class="{ 'u-mb--s06': model.link.url }"
    >
    <a
      v-if="model.link && model.link.url"
      :href="model.link.url"
      class="c-button"
      v-bind="model.link.attributes"
      @click="$emit('ctaClick')"
    >
      {{ model.link.text }}
    </a>
  </div>
</template>

<script>
import CI from '@/components/CI.vue'
import { IconInfo } from '@/utils/icons'

export default {
  components: {
    CI
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      IconInfo
    }
  }
}
</script>
