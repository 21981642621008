<template>
  <div
    class="c-recipes-block"
  >
    <Slider
      v-if="pagesReady"
      ref="slider"
      :key="sliderKey"
      :title="model.title"
      :link="model.link"
      type="grid"
      @update="onUpdate"
    >
      <div
        v-for="(page, index) in pages"
        :key="index"
        class="c-slide"
        :class="{ 'is-partial': page.columns.length < 3 }"
      >
        <div
          v-for="(column, columnIndex) in page.columns"
          :key="columnIndex"
          class="c-slide__column"
          :class="{ 'is-single': column.single }"
        >
          <a
            v-for="(item, itemIndex) in column.items"
            :key="itemIndex"
            :href="item.link.url"
            class="c-recipe-card"
          >
            <div class="c-recipe-card__media">
              <Picture
                v-if="item.image"
                :src="item.image.src"
                class="c-recipe-card__media__image"
              />
            </div>
            <div class="c-recipe-card__content">
              <div class="c-recipe-card__text">
                <h4 class="c-recipe-card__title">{{ item.title }}</h4>
              </div>
            </div>
          </a>
        </div>
      </div>
    </Slider>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Picture from '@/components/Picture.vue'
import Slider from '@/components/Slider.vue'

export default {
  components: {
    Picture,
    Slider
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const pagesReady = ref(false)
    const usePages = ref(false)
    const pageModel = ref(null)
    const sliderKey = ref(0)

    const columnize = (pageChunk, columnSize) => {
      const columns = []
      let takeOne = false
      while (pageChunk.length > 0) {
        columns.push({
          items: pageChunk.splice(0, (takeOne ? 1 : columnSize)),
          single: takeOne
        })
        takeOne = !takeOne
      }

      return {
        columns
      }
    }

    const splitToPages = (arr, pageSize) => {
      const pages = []
      while (arr.length > 0) {
        const chunk = arr.splice(0, pageSize)
        pages.push(columnize(chunk, 2))
      }

      return pages
    }

    const getPages = (isDesktop) => ((isDesktop) ? splitToPages([...props.model.items], 5) : splitToPages([...props.model.items], 1))

    const handlePageModel = () => {
      const isDesktop = (window.innerWidth > 768) || false

      if (pageModel && usePages.value != isDesktop) {
        pageModel.value = getPages(isDesktop)
        usePages.value = isDesktop

        // force slider to re-render when pageModel is updated
        sliderKey.value += 1
      }
    }

    onMounted(() => {
      usePages.value = (window.innerWidth > 768) || false
      pageModel.value = getPages(usePages.value)
      handlePageModel()
      pagesReady.value = true
    })

    return {
      sliderKey,
      pagesReady,
      pages: pageModel,
      onUpdate: handlePageModel
    }
  }
}
</script>
