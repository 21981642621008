<template>
  <div class="c-map-block o-container">
    <div class="map-locator-block__content">
      <h2 class="map-locator-block__heading">
        <slot name="header" />
      </h2>
      <slot name="search" />
      <div
        class="map-locator-block__overlay"
        :class="{ 'show-list': displayType == 'list', 'show-details': selectedItem }"
      >
        <div
          class="map-locator-block__overlay-list"
          :class="{
            'is-small-on-mobile': smallOnMobile
          }"
        >
          <slot
            name="list"
            :select-item="onItemClick"
          />
        </div>
        <div
          v-if="selectedItem"
          class="map-locator-block__overlay-details"
        >
          <slot
            :selectedItem="selectedItem"
            :displayType="displayType"
            name="details"
          />
        </div>
        <button
          v-if="selectedItem"
          class="close"
          @click.prevent="onItemClose"
        />
      </div>

      <div
        class="map-locator-block__map-wrapper"
        :class="{
          'is-small-on-mobile': smallOnMobile,
          'show-list': displayType == 'list',
          'show-details': selectedItem
        }"
      >
        <slot name="map">
          <google-map
            :markers="items"
            :theme="model.theme"
            :selected="selectedMarkerId"
            @markerClick="onMarkerClick"
          />
        </slot>

        <div class="map-locator-block__switch">
          <div class="switch__inner">
            <label
              :for="`display-map-${uid}`"
              :class="{ 'selected': displayType == 'map' }"
            >
              <input
                :id="`display-map-${uid}`"
                v-model="displayType"
                type="radio"
                value="map"
              >
              {{ model.translations.displayModeMap }}
            </label>
            <label
              :for="`display-list-${uid}`"
              :class="{ 'selected': displayType == 'list' }"
            >
              <input
                :id="`display-list-${uid}`"
                v-model="displayType"
                type="radio"
                value="list"
              >
              {{ model.translations.displayModeList }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from '@/components/map/google-map.vue'
import gtmUtil from '@/utils/gtm-util'

let uid = 0

export default {
  components: {
    GoogleMap
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    smallOnMobile: {
      type: Boolean,
      default: false
    },
    listMode: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    },
    mapType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedItem: null,
      displayType: 'map',
      mapAction: this.action,
      mapKey: 0
    }
  },
  computed: {
    selectedMarkerId() {
      return this.selectedItem ? this.selectedItem.id : null
    }
  },
  watch: {
    displayType(value) {
      this.onItemClose()
      const toggleMode = value == 'list' ? 'on' : 'off'
      gtmUtil.trackMapToggleListClick(toggleMode, this.mapType)
    },
    listMode(val) {
      if (val) {
        this.displayType = 'list'
      }
    },
    action(val) {
      this.mapAction = val
    }
  },
  beforeCreate() {
    this.uid = uid.toString()
    uid += 1
  },
  methods: {
    onMarkerClick({ id }) {
      this.mapAction = 'mapselect'
      this.selectedItem = this.items.find((item) => item.id == id)
      gtmUtil.trackMapMarkerClick(this.selectedItem.title, this.mapType)
    },
    onItemClick(item) {
      this.mapAction = 'listselect'
      this.selectedItem = item
    },
    onItemClose() {
      this.selectedItem = null
    }
  }
}
</script>
