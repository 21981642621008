<template>
  <div class="c-article-list">
    <ArticleCard
      v-for="(item, index) in items"
      :key="index"
      :model="item"
    />
  </div>
</template>

<script>
import ArticleCard from '@/components/ArticleCard.vue'

export default {
  components: {
    ArticleCard
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
