<template>
  <form
    class="c-form c-redirect-tool-block u-width-text"
    @submit.prevent="onSubmit"
  >
    <div>
      <h5 class="u-mb--s04">
        Ean and country data
      </h5>
      <div class="c-form-row">
        <label
          for="product-ean"
          class="u-flex"
        >
          Product EAN
        </label>
        <input
          id="product-ean"
          v-model="ean"
          required
          type="text"
          name="productEan"
          tabindex="0"
          placeholder="Enter 13 digit EAN"
          pattern="\d{13}"
        >
      </div>
      <div class="c-form-row">
        <label
          for="product-language"
          class="u-flex"
        >
          Language
        </label>
        <select
          id="product-language"
          v-model="language"
          required
        >
          <option
            disabled
            hidden=""
            value=""
          >
            Choose language
          </option>
          <option
            v-for="(key, value) in model.languages"
            :key="key"
            :value="key"
            v-text="value"
          />
        </select>
      </div>
      <div class="c-form-row">
        <button
          class="c-button c-button--solid"
          type="submit"
          :disabled="!submitIsEnabled"
        >
          Get Wholesalers
        </button>
      </div>
    </div>
    <div
      v-if="wholesalers || noWholesalers"
      class="u-overflow-hidden"
    >
      <h5 class="u-mb--s04">
        Wholesalers result
      </h5>
      <template v-if="wholesalers">
        <div class="c-form-row">
          <label
            for="wholesaler"
            class="u-flex"
          >
            Wholesaler
          </label>
          <select
            id="wholesaler"
            v-model="wholesalerId"
            required
          >
            <option
              disabled
              hidden
              value=""
            >
              Choose wholesaler
            </option>
            <option
              v-for="(value, key) in wholesalers"
              :key="key"
              :value="key"
              v-text="value"
            />
          </select>
        </div>
        <div class="c-form-row">
          <label
            for="block-type"
            class="u-flex"
          >
            Use link in
          </label>
          <select
            id="block-type"
            v-model="placement"
            required
          >
            <option
              v-for="(value, key) in model.placements"
              :key="key"
              :value="key"
              v-text="value"
            />
          </select>
        </div>
      </template>
      <div
        v-else-if="noWholesalers"
        class="c-form-row"
      >
        Unfortunately no wholesalers are found for this product...
      </div>
      <div
        v-else-if="hasError"
        class="c-form-row"
      >
        Something went wrong...
      </div>
      <div
        v-if="redirectPageUrl"
        class="c-form-row"
      >
        <div>
          <a
            :href="redirectPageUrl"
            class="js-copy-link"
            :title="redirectPageUrl"
            rel="noopener noreferrer"
          >
            {{ redirectPageUrl }}
          </a>
        </div>
        <button
          class="c-button-link"
          @click="useCopyLink($event.currentTarget, redirectPageUrl)"
        >
          Copy to clipboard
          <span class="copied--checkmark">
            &check;
          </span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, computed, watch } from 'vue'
import axios from 'axios'
import useCopyLink from '@/composition/useCopyLink'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup({
    model
  }) {
    const language = ref('')
    const placement = ref(Object.keys(model.placements)[0])
    const ean = ref('')
    const wholesalers = ref()
    const wholesalerId = ref('')
    const redirectPageUrl = ref()
    const loading = ref(false)
    const noWholesalers = ref(false)
    const hasError = ref(false)
    const { redirectPageUrlTemplate } = model
    const submitIsEnabled = computed(() => ean.value && language.value && !loading.value)

    const guid = () => {
      const w = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
      return `${w()}${w()}-${w()}-${w()}-${w()}-${w()}${w()}${w()}`
    }

    const getWholesalers = async () => {
      loading.value = true
      const { data, status } = await axios.get(`/api/redirecttool/${language.value}/product`, {
        params: {
          ean: ean.value
        }
      })

      if (status === 204) {
        noWholesalers.value = true
      }
      loading.value = false
      return data.wholesalers
    }

    const getRedirectUrl = () => redirectPageUrlTemplate
      .replace('{language}', language.value)
      .replace('{ean}', ean.value)
      .replace('{placement}', placement.value)
      .replace('{wholesaler_id}', wholesalerId.value)
      .replace('&qr_code_id=', placement.value === 'qr' ? '&qr_code_id=' : '')
      .replace('{qr_code_id}', placement.value === 'qr' ? guid : '')

    const onSubmit = async () => {
      try {
        hasError.value = false
        wholesalers.value = await getWholesalers()
      } catch (e) {
        hasError.value = true
      }
    }

    watch([wholesalerId, placement], () => {
      if (wholesalerId.value) {
        redirectPageUrl.value = ''
        setTimeout(() => {
          redirectPageUrl.value = getRedirectUrl()
        }, 300)
      }
    })

    watch([
      ean, language
    ], () => {
      noWholesalers.value = false
      wholesalers.value = null
      wholesalerId.value = ''
      redirectPageUrl.value = ''
    })

    return {
      ean,
      language,
      placement,
      submitIsEnabled,
      wholesalers,
      wholesalerId,
      redirectPageUrl,
      noWholesalers,
      hasError,

      onSubmit,
      getRedirectUrl,
      useCopyLink
    }
  }
}
</script>
