const API_CONFIG = {
  apiKey: 'AIzaSyDbyx0j-wPao5QxG-J-zbsIyfDDSpz6Eig'
}

const MAP_SETTINGS = {
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  backgroundColor: '#f7f7f7',
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControl: false,
  zoom: 6,
  minZoom: 2,
  maxZoom: 11
}

const MAP_COLORS = {
  light: {
    color: '#000000',
    backgroundColor: '#f7f7f7',
    markerBackgroundColor: '#f7f7f7'
  },
  dark: {
    color: '#f7f7f7',
    backgroundColor: '#000000',
    markerBackgroundColor: '#000000'
  },
  gray: {
    color: '#000000',
    backgroundColor: '#f7f7f7',
    markerBackgroundColor: '#f6f6f6'
  },
  yellow: {
    color: '#000000',
    backgroundColor: '#f7f7f7',
    markerBackgroundColor: '#FFEABB'
  },
  mint: {
    color: '#000000',
    backgroundColor: '#f7f7f7',
    markerBackgroundColor: '#C0EBE7'
  },
  green: {
    color: '#000000',
    backgroundColor: '#f7f7f7',
    markerBackgroundColor: '#B9DCC5'
  },
  red: {
    color: '#000000',
    backgroundColor: '#f7f7f7',
    markerBackgroundColor: '#E1BCBC'
  }
}

const THEME_STYLES = {
  default: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'road.highway',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'road.arterial',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'road.local',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'landscape.man_made',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'landscape.natural.terrain',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.country',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.province',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.locality',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.province',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.neighborhood',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative',
      elementType: 'all',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    }
  ],
  dark: [
    {
      markerTextColor: '#00ff00',
      markerBackgroundColor: '#ff00ff',
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#f7f7f7'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#131313'
        }
      ]
    }
  ],
  light: [
    {
      markerTextColor: '#00ff00',
      markerBackgroundColor: '#ff00ff',
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#131313'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f7f7f7'
        }
      ]
    }
  ]
}

THEME_STYLES.yellow = THEME_STYLES.light
THEME_STYLES.mint = THEME_STYLES.light
THEME_STYLES.gray = THEME_STYLES.light
THEME_STYLES.green = THEME_STYLES.light
THEME_STYLES.red = THEME_STYLES.light

export {
  MAP_SETTINGS,
  MAP_COLORS,
  API_CONFIG,
  THEME_STYLES
}
