import api from './_config'

export default {
  getSearchItems({
    take,
    searchType,
    sorting,
    activeCategories = [],
    terms = [],
    language = null,
    contentLinkId
  }, { skip }) {
    const params = {
      skip,
      take,
      terms,
      sortOrder: sorting,
      contentLinkId
    }
    if (searchType === 'Product' || searchType === 'Article') {
      params.tags = activeCategories.filter((x) => !x.isBrand && !x.isLabel && !x.isDealer).map((tag) => tag.uid)
      params.brands = activeCategories.filter((x) => x.isBrand && !x.isLabel && !x.isDealer).map((brand) => brand.uid)
      params.labels = activeCategories.filter((x) => x.isLabel && !x.isDealer).map((label) => label.uid)
      params.dealers = activeCategories.filter((x) => x.isDealer).map((dealer) => dealer.uid)
    } else {
      params.categories = activeCategories.filter((x) => x.uid.startsWith('rdb')).map((category) => category.uid)
      params.tags = activeCategories.filter((x) => x.uid.startsWith('tdb')).map((tag) => tag.uid)
    }

    return api.get(`/api/${language}/content/${searchType}/search/`, { params })
  },
  getCompanyAutocomplete(query, apiUrl) {
    const params = {
      query: encodeURIComponent(query)
    }
    return api.get(apiUrl, { params })
  }
}
