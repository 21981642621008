import scrollToAnchor from '@/composition/scrollToSection'

export default () => {
  let links = null
  if (document.querySelector('.c-employee-block') !== null) {
    links = document.querySelectorAll('.c-employee-list-links a')
  }

  const scrollToSection = (e) => {
    e.preventDefault()
    scrollToAnchor(e.target, (-100))
  }

  const initEmployeeListScroll = () => {
    if (links === null) {
      return
    }
    links.forEach((link) => {
      link.addEventListener('click', scrollToSection)
    })
  }

  return {
    initEmployeeListScroll
  }
}
