<template>
  <Teleport to="body">
    <transition name="modal">
      <focus-trap
        v-if="isModalOpen"
        v-model="isModalOpen"
        :initial-focus="() => closeButton"
      >
        <div
          class="c-modal"
          :class="[
            `c-modal-type--${type}`,
            themeCssClass
          ]"
          @click="onClickAway"
          @keyup="onClickAway"
        >
          <div
            v-if="!transparentOverlay"
            class="c-modal-overlay"
          />
          <div
            ref="modalContainer"
            class="c-modal__container"
            :class="[
              { 'is-fullscreen': isMobileFullscreen },
              { 'c-modal__container--reverse-action-color': reverseActionsColor }
            ]"
            @click.stop
            @keyup.stop
          >
            <button
              ref="closeButton"
              class="c-modal__close-button"
              @click="onCloseModal"
            >
              <Icon
                :icon="iconClose"
                :size="16"
              />
              <span class="u-hide-on-screenonly">{{ closeText }}</span>
            </button>

            <div
              class="c-modal__content"
              :class="{ 'is-full-width': isFullWidthContent }"
              body-scroll-lock-ignore
            >
              <slot />
            </div>
          </div>
        </div>
      </focus-trap>
    </transition>
  </Teleport>
</template>

<script>
import {
  onMounted, onUnmounted, ref, computed
} from 'vue'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { FocusTrap } from 'focus-trap-vue'
import Icon from '@/components/Icon.vue'
import iconClose from '@/assets/img/icons/delete.svg'
import gtmUtil from '@/utils/gtm-util'

const allowTouchMove = (el) => {
  while (el && el !== document.body) {
    if (el.getAttribute('body-scroll-lock-ignore') !== null) {
      return true
    }
    // eslint-disable-next-line no-param-reassign
    el = el.parentElement
  }
  return false
}

export default {
  components: {
    FocusTrap,
    Icon
  },
  props: {
    closeText: {
      type: String,
      default: 'close'
    },
    reverseActionsColor: {
      type: Boolean,
      default: false
    },
    isFullWidthContent: {
      type: Boolean,
      default: false
    },
    isMobileFullscreen: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'white'
    },
    transparentOverlay: {
      type: Boolean,
      default: false
    },
    disableBodyScroll: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  setup(props, context) {
    const modalContainer = ref(null)
    const closeButton = ref(null)
    const isModalOpen = ref(false)
    const themeCssClass = computed(() => `u-theme--${props.theme}`)

    const onOpenModal = () => {
      isModalOpen.value = true
      setTimeout(() => {
        if (props.disableBodyScroll) {
          disableBodyScroll(modalContainer.value, {
            allowTouchMove
          })
        }
        gtmUtil.initModalHandler()
      }, 10)
    }

    const onCloseModal = () => {
      isModalOpen.value = false
      if (props.disableBodyScroll) {
        enableBodyScroll(modalContainer.value)
      }
      context.emit('onCloseClick')
    }

    const onKeydown = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        onCloseModal()
      }
    }

    onMounted(() => {
      document.addEventListener('keydown', (e) => onKeydown(e))
    })

    onUnmounted(() => {
      clearAllBodyScrollLocks()
      document.removeEventListener('keydown', (e) => onKeydown(e))
    })

    return {
      modalContainer,
      closeButton,
      isModalOpen,
      themeCssClass,
      iconClose,

      onOpenModal,
      onCloseModal,
      onClickAway: onCloseModal
    }
  }
}
</script>
