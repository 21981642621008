<template>
  <div class="c-video">
    <div
      v-if="isYoutube"
      class="c-video__iframe-container"
    >
      <iframe
        :src="src"
        title="Video"
        frameborder="0"
        allowfullscreen
      />
    </div>
    <template v-else>
      <video-js
        ref="playerRef"
        class="video-js"
      />
      <img
        v-if="poster"
        :src="poster"
        :alt="heading"
      >

      <button
        v-if="showPlayBtnOverlay"
        class="c-video-play-button"
        @click="onHandleClick"
      >
        <span class="u-m--a">
          <CI
            :icon="IconPlay"
            size="32"
            fill="none"
          />
        </span>
      </button>
    </template>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import {
  onBeforeUnmount, onMounted, ref, computed
} from 'vue'
import videojs from 'video.js'
import CI from '@/components/CI.vue'
import { IconPlay } from '@/utils/icons'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    CI
  },
  props: {
    src: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    poster: {
      type: String,
      default: ''
    },
    playsinline: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    },
    showBtnPlay: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: 'Default'
    }
  },
  setup(props) {
    let player
    const playerRef = ref()
    const trackingDuration = []
    const availableTrackingDurations = [90, 75, 50, 25, 10]
    const showPlayBtnOverlay = ref(props.showBtnPlay)
    const showPoster = ref(props.showBtnPlay && !props.autoplay)
    const isPlaying = ref(false)
    const isYoutube = computed(() => props.type === 'youtube')
    const videoOptions = computed(() => {
      const {
        src,
        playsinline,
        autoplay,
        loop,
        muted,
        controls
      } = props

      return {
        playsinline,
        autoplay,
        loop,
        muted,
        controls,
        liveui: controls,
        sources: [
          {
            src,
            type: 'application/x-mpegURL'
          }
        ]
      }
    })

    const onHandleClick = () => {
      if (showPlayBtnOverlay.value) {
        player.play()
        showPlayBtnOverlay.value = false
        gtmUtil.videoStart(props.heading)
      }
    }

    const getPercent = (tempPercent, percentValue) => {
      if (tempPercent > percentValue && !trackingDuration.includes(percentValue)) {
        return percentValue
      }
      return 0
    }

    onMounted(() => {
      if (!isYoutube.value) {
        player = videojs(playerRef.value, videoOptions.value)

        if (!props.autoplay) {
          player.on('ended', () => {
            gtmUtil.videoComplete(props.heading)
          })
          player.on('play', () => {
            showPoster.value = false
          })
          player.on('timeupdate', () => {
            const tempPercent = Math.trunc((player.currentTime() * 100) / player.duration())
            let percent = 0
            for (let i = 0; i < availableTrackingDurations.length; i += 1) {
              percent = getPercent(tempPercent, availableTrackingDurations[i])
              if (percent !== 0) {
                break
              }
            }

            if (percent !== 0 && !trackingDuration.includes(percent)
              && availableTrackingDurations.includes(percent)) {
              gtmUtil.videoProgress(props.heading, percent)
              trackingDuration.push(percent)
            }
          })
        }
      }
    })

    onBeforeUnmount(() => {
      if (player) {
        player.dispose()
      }
    })

    return {
      isPlaying,
      playerRef,
      showPlayBtnOverlay,
      isYoutube,
      IconPlay,

      onHandleClick
    }
  }
}
</script>
