<template>
  <div>
    <div class="farm-list__items">
      <slot
        :pagedItems="pagedItems"
      />
    </div>
    <div class="nav-container">
      <button
        class="nav nav--prev"
        :disabled="!allowPrev"
        @click="prev"
      />
      <button
        class="nav nav--next"
        :disabled="!allowNext"
        @click="next"
      />
    </div>
  </div>
</template>

<script>
import { useMq } from 'vue3-mq'
import gtmUtil from '@/utils/gtm-util'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 8
    },
    mapType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      skip: 0,
      takeOnMobile: 6,
      take: this.pageSize
    }
  },
  computed: {
    allowPrev() {
      return this.currentPage > 0
    },
    allowNext() {
      return this.currentPage < this.totalPages - 1
    },
    totalPages() {
      return Math.ceil(this.items.length / this.take)
    },
    currentPage() {
      return this.skip / this.take
    },
    pagedItems() {
      return this.items.slice(this.skip, this.skip + this.take)
    }
  },
  watch: {
    items() {
      this.skip = 0
    }
  },
  mounted() {
    const pageSizeOnMobile = this.pageSize < this.takeOnMobile ? this.pageSize : this.takeOnMobile
    this.take = useMq().current === 'mobile' ? pageSizeOnMobile : this.pageSize
  },
  methods: {
    prev() {
      this.skip -= this.take
      gtmUtil.trackMapListArrowClick(this.mapType, 'left')
    },
    next() {
      this.skip += this.take
      gtmUtil.trackMapListArrowClick(this.mapType, 'right')
    }
  }
}
</script>
