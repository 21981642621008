<template>
  <div class="c-overview u-pb--s08 o-container">
    <SearchInput v-if="isMobile" />
    <SearchFilters class="c-overview-filters" />

    <div class="c-overview-content">
      <SearchHeader />
      <SearchActiveTags class="u-mb--s06" />
      <SearchItems />
    </div>
  </div>
</template>

<script>
import { watch, computed } from 'vue'
import { useMq } from 'vue3-mq'
import SearchFilters from '@/components/search/SearchFilters.vue'
import SearchItems from '@/components/search/SearchItems.vue'
import SearchHeader from '@/components/search/SearchHeader.vue'
import SearchInput from '@/components/search/SearchInput.vue'
import SearchActiveTags from '@/components/search/SearchActiveTags.vue'
import searchQueryState from '@/composition/search/searchQuery'
import searchConfig from '@/composition/search/searchConfig'
import routerHelper from '@/composition/search/searchRouterHelper'
import router from '@/composition/search/searchRouter'

export default {
  components: {
    SearchHeader,
    SearchFilters,
    SearchItems,
    SearchInput,
    SearchActiveTags
  },
  props: {
    searchConfiguration: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const isMobile = computed(() => useMq().current === 'mobile' || useMq().current === 'tablet')
    searchConfig.searchConfigurationUse(props.searchConfiguration, props.baseUrl)
    router.addRoute({
      path: props.baseUrl,
      name: 'filters'
    })

    const { getRouteData, updateRoute } = routerHelper.useRouterHelper(router, props.baseUrl)
    const routeData = getRouteData()
    routeData.language = props.language
    const { searchResult } = searchQueryState.searchQueryUse(routeData)

    watch(
      () => [searchResult.loading],
      () => {
        if (!searchResult.loading) {
          updateRoute()
        }
      }
    )

    return {
      isMobile
    }
  }
}
</script>
