<template>
  <div class="c-search-header">
    <SearchInput v-if="!isMobile" />

    <div class="c-search-header__sort">
      <span class="c-search-header__count">{{ totalCountText }}</span>
      <SearchSorting />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useMq } from 'vue3-mq'
import searchConfig from '@/composition/search/searchConfig'
import searchQueryState from '@/composition/search/searchQuery'
import SearchInput from '@/components/search/SearchInput.vue'
import SearchSorting from '@/components/search/SearchSorting.vue'

export default {
  components: {
    SearchInput,
    SearchSorting
  },
  setup() {
    const isMobile = computed(() => useMq().current === 'mobile' || useMq().current === 'tablet')
    const { searchConfiguration } = searchConfig.searchConfigurationUse()
    const translations = computed(() => searchConfiguration.translations)
    const { searchResult } = searchQueryState.searchQueryUse()

    const totalCountText = computed(() => (translations.value
      ? translations.value.totalCountMessage.replace('{0}', searchResult.totalCount)
      : ''
    ))

    return {
      totalCountText,
      isMobile
    }
  }
}
</script>
