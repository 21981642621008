import { computed, reactive } from 'vue'
import { useMq } from 'vue3-mq'
import searchApi from '@/services/search'
import searchConfig from '@/composition/search/searchConfig'

const searchResultState = reactive({
  loading: false,
  allItemsLoaded: false,
  totalCount: 0,
  hasResult: false,
  items: []
})

export default {
  useSearchResult() {
    const state = searchResultState
    const { searchConfiguration } = searchConfig.searchConfigurationUse()

    const isMobile = useMq().current === 'mobile' || useMq().current === 'tablet'
    let itemGroupNr = 8
    if (isMobile && searchConfiguration.type === 'Recipe') {
      itemGroupNr = 9
    } else if (searchConfiguration.type === 'Product') {
      itemGroupNr = isMobile ? 2 : 6
    } else if (searchConfiguration.type === 'Article') {
      itemGroupNr = 9
    }

    function setGifsToShow(items) {
      const showGifThreshold = isMobile ? 5 : 3
      let nextIndexToCheck = (showGifThreshold) - 1

      items.forEach((item, index) => {
        if (index === nextIndexToCheck) {
          if (item.animatedImage) {
            item.showGif = true
            nextIndexToCheck += showGifThreshold
          } else nextIndexToCheck += 1
        }
      })

      return items
    }

    function insertBreakerBlocks(query) {
      const blocksPerPage = (query.take / itemGroupNr) - 1
      const blocksToSkip = blocksPerPage * (query.page - 1)
      // Stop if there are no more blocks to render
      if (blocksToSkip >= searchConfiguration.breakerBlocks.length) return

      searchConfiguration.breakerBlocks.forEach((block, index) => {
        const indexToInsert = ((index + 1) * (itemGroupNr)) + index
        const existingBreakerBlock = state.items.find((item) => item.id && item.id === block.id)

        if (indexToInsert < state.items.length && (index + 1) > blocksToSkip) {
          // Don't insert breaker block if it already exists in the list
          if (existingBreakerBlock && block.id === existingBreakerBlock.id) return
          state.items.splice(indexToInsert, 0, block)
        }
      })
    }

    async function fetchData(searchQuery) {
      state.allItemsLoaded = false
      const skip = searchQuery.take * (searchQuery.page - 1)
      const response = await searchApi.getSearchItems(searchQuery, { skip })
      state.totalCount = response.data.total
      state.hasResult = response.data.total > 0

      state.items = setGifsToShow(response.data.items)
      if (searchConfiguration.breakerBlocks?.length) insertBreakerBlocks(searchQuery)

      if (searchQuery.take >= state.totalCount) {
        state.allItemsLoaded = true
      }
    }

    async function fetchExtraItems(searchQuery) {
      const skip = searchQuery.take * (searchQuery.page - 1)
      const response = await searchApi.getSearchItems(searchQuery, { skip })

      const extraItems = setGifsToShow(response.data.items)
      state.items.push(...extraItems)
      if (searchConfiguration.breakerBlocks?.length) insertBreakerBlocks(searchQuery)

      if (skip + searchQuery.take >= state.totalCount) {
        state.allItemsLoaded = true
      }
    }

    async function search(searchQuery) {
      if (!state.loading) {
        state.items = []
        state.loading = true
        fetchData(searchQuery).then(() => {
          state.loading = false
        })
      }
    }

    async function searchMore(searchQuery) {
      if (state.totalCount === state.items.length || state.allItemsLoaded) {
        return
      }

      if (!state.loading) {
        state.loading = true
        fetchExtraItems(searchQuery).then(() => {
          state.loading = false
        })
      }
    }

    return {
      searchResult: state,
      search,
      searchMore,
      loading: computed(() => state.loading),
      allItemsLoaded: computed(() => state.allItemsLoaded)
    }
  }
}
