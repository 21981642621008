<template>
  <button
    class="c-button c-button--solid"
    @click.stop.prevent="openBBW"
  >
    <CI
      :icon="IconCart"
      class="c-base-icon"
      size="16"
    />
    <template v-if="slotContent">
      <span>
        {{ slotContent }}
      </span>
    </template>
    <slot v-else />
  </button>
</template>

<script>
import { onMounted } from 'vue'
import CI from '@/components/CI.vue'
import { IconCart } from '@/utils/icons'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    CI
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showOnLoad: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'normal'
    },
    slotContent: {
      type: String,
      default: ''
    }
  },
  setup({
    model,
    showOnLoad,
    placement
  }) {
    const {
      name,
      subtitle,
      imageUrl,
      units,
      description,
      categoryName,
      brandName
    } = model

    const ean = model.ean || '0000000000000'

    const openBBW = () => {
      if (window.BBWIsLoaded) {
        window.BBWIsLoaded.then(() => {
          window.openBBWModal(ean, {
            ean,
            name,
            subtitle,
            imageUrl,
            units,
            description,
            categoryName,
            brandName
          }, placement)

          gtmUtil.trackBBWButtonClick({
            placement,
            productEAN: ean,
            productName: name,
            categoryName,
            brandName
          })
        })
      }
    }

    const showBBWOnLoad = () => {
      if (showOnLoad && window.location.search) {
        const searchParams = new URLSearchParams(window.location.search)
        const modalParam = searchParams.get('modal')

        if (modalParam === 'vendors') {
          document.body.addEventListener('OneTrustBannerInteractionState', () => {
            setTimeout(openBBW, 300) // Add delay to make sure gtm container is loaded
          })
        }
      }
    }

    onMounted(() => {
      showBBWOnLoad()
    })

    return {
      IconCart,

      openBBW
    }
  }
}
</script>
