const formatLineBreaks = (txt) => (txt || '').replace(/\n/g, '<br>')

const createDownloadLink = (url, newWindow, fileName) => {
  const link = document.createElement('a')
  link.target = newWindow ? '_blank' : '_self'
  link.download = fileName
  link.href = url
  link.click()
}

const createDownloadBlobLink = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export {
  formatLineBreaks,
  createDownloadLink,
  createDownloadBlobLink
}
