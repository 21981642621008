import scrollToAnchor from '@/composition/scrollToSection'

export default () => {
  let menuHeight = null
  let links = null
  const sections = []
  if (document.getElementById('sticky-menu') !== null) {
    menuHeight = document.getElementById('sticky-menu').offsetHeight
    links = document.querySelectorAll('.c-sticky-menu__link')
    links.forEach((link) => sections.push(document.getElementById(link.hash.substr(1))))
  }

  const scrollToSection = (e) => {
    if (sections[0] == null) {
      return
    }
    e.preventDefault()
    scrollToAnchor(e.target, menuHeight - 110) // 110px - header height, same across all devices
    window.history.pushState(null, null, e.target.hash)
  }

  const highlightMenuLink = (target) => {
    links.forEach((link) => {
      link.classList.remove('selected')
    })
    target.classList.add('selected')
  }

  const handleScroll = () => {
    sections.forEach((section) => {
      if (!section) {
        return
      }
      const sectionTopPosition = section.offsetTop
      if (Math.ceil(window.scrollY + menuHeight - 110) >= sectionTopPosition) { // 110px - header height, same across all devices
        links.forEach((link) => {
          if (link.hash === `#${section.attributes.id.value}`) {
            highlightMenuLink(link)
          }
        })
      } else if (Math.ceil(window.scrollY + menuHeight - 110) < sections[0].offsetTop) {
        links.forEach((link) => {
          link.classList.remove('selected')
        })
      }
    })
  }

  const initStickyMenu = () => {
    if (sections[0] == null) {
      return
    }
    links.forEach((link) => {
      link.addEventListener('click', scrollToSection)
    })
    handleScroll()
    window.addEventListener('scroll', handleScroll)
  }

  return {
    initStickyMenu
  }
}
