<template>
  <picture>
    <source
      v-if="extraWideValue"
      media="(min-width: 1600px)"
      :srcset="srcset(src, extraWideValue, extraWideHash)"
    >
    <source
      v-if="wideValue"
      media="(min-width: 1200px)"
      :srcset="srcset(src, wideValue, wideHash)"
    >
    <source
      v-if="desktopValue"
      media="(min-width: 992px)"
      :srcset="srcset(src, desktopValue, desktopHash)"
    >
    <source
      v-if="tabletValue"
      media="(min-width: 768px)"
      :srcset="srcset(mobileSrcValue, tabletValue, tabletHash, true, tablet2xHash)"
    >
    <img
      v-if="mobile"
      :class="imgClass"
      :src="resize(mobileSrcValue, mobile, mobileHash)"
      :srcset="srcset(mobileSrcValue, mobile, mobileHash, true, mobile2xHash)"
      :alt="altValue"
      :loading="loading"
    >
    <img
      v-else
      :class="imgClass"
      :src="mobileSrcUrl"
      :alt="altValue"
      :loading="loading"
    >
    <slot />
  </picture>
</template>

<script>
import imageResizer from '@/utils/image-resizer'

export default {
  props: {
    src: {
      type: [Object, String],
      required: true
    },
    mobileSrc: {
      type: [Object, String],
      default: null
    },
    imgClass: {
      type: String,
      default: null
    },
    mobile: {
      type: Array,
      default: () => [375, 0]
    },
    mobileHash: {
      type: String,
      default: ''
    },
    mobile2xHash: {
      type: String,
      default: ''
    },
    tablet: {
      type: Array,
      default: null
    },
    tabletHash: {
      type: String,
      default: ''
    },
    tablet2xHash: {
      type: String,
      default: ''
    },
    desktop: {
      type: Array,
      default: null
    },
    desktopHash: {
      type: String,
      default: ''
    },
    wide: {
      type: Array,
      default: null
    },
    wideHash: {
      type: String,
      default: ''
    },
    extraWide: {
      type: Array,
      default: null
    },
    extraWideHash: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: null
    },
    attributes: {
      type: Object,
      default: null
    },
    imageResizer: {
      type: Object,
      default: () => ({ name: 'ImageResizingNet' })
    },
    alt: {
      type: String,
      default: () => document.title
    }
  },
  setup(props) {
    const mobileSrcValue = props.mobileSrc ?? props.src
    let tabletValue = props.tablet
    let desktopValue = props.desktop
    let wideValue = props.wide
    let extraWideValue = props.extraWide
    const altValue = (props.alt && props.alt.trim() !== '') ? props.alt : document.title
    const mobileSrcUrl = mobileSrcValue?.url || mobileSrcValue

    if (props.fullWidth) {
      tabletValue ??= [720, 0]
      desktopValue ??= [960, 0]
      wideValue ??= [1500, 0]
      extraWideValue ??= [1920, 0]
    }

    const resize = (src, sizes, hash) => {
      const resizer = imageResizer[props.imageResizer.name]
      return resizer
        .resize(src, sizes[0], sizes[1], props.options, hash)
    }

    const srcset = (src, sizes, hash, add2x = false, twoXHash = '') => {
      const defaultSize = resize(src, sizes, hash)

      if (!add2x) {
        return defaultSize
      }

      const multipliedSize = resize(src, [sizes[0] * 2, sizes[1] * 2], twoXHash)
      return `${defaultSize}, ${multipliedSize} 2x`
    }

    // This is a temporary workaround for a problem with rtl images lazy loading of images in sliders
    const isRTL = document.documentElement.dir === 'rtl'
    const loading = isRTL ? 'eager' : 'lazy'

    return {
      mobileSrcValue,
      tabletValue,
      desktopValue,
      wideValue,
      extraWideValue,
      mobileSrcUrl,
      altValue,
      loading,
      resize,
      srcset
    }
  }
}
</script>
