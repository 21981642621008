<template>
  <div
    v-if="hasFilters"
    class="c-active-tags"
  >
    <div class="c-active-dealer-tags">
      <button
        v-for="(category, index) in activeDealerCategories"
        :key="index"
        class="c-tag--dealer active"
        @click="removeFilter(category)"
      >
        <img
          :src="category.logo"
          :alt="category.name"
          width="85"
          height="30"
        >
        <Icon
          :icon="iconClose"
        />
      </button>

      <button
        v-if="hasDealerCategories && !hasDefaultFilters"
        class="c-button-link"
        @click="removeAllFilters"
      >
        {{ translations.clearAllText }}
      </button>
    </div>
    <button
      v-for="(term, index) in activeTerms"
      :key="index"
      class="c-tag active"
      @click="removeSearchTerm(term)"
    >
      {{ term }}
      <Icon
        :icon="iconClose"
      />
    </button>

    <button
      v-for="(category, index) in activeCategoriesWithoutDealers"
      :key="index"
      class="c-tag active"
      @click="removeFilter(category)"
    >
      {{ category.name }}
      <Icon
        :icon="iconClose"
      />
    </button>

    <button
      v-if="hasDefaultFilters"
      class="c-button-link"
      @click="removeAllFilters"
    >
      {{ translations.clearAllText }}
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'
import iconClose from '@/assets/img/icons/x-mark.svg'
import searchQueryState from '@/composition/search/searchQuery'
import searchConfig from '@/composition/search/searchConfig'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Icon
  },
  setup() {
    const { searchConfiguration } = searchConfig.searchConfigurationUse()

    const {
      activeTerms,
      activeCategories,
      removeTerm,
      removeCategory,
      resetAllFilters
    } = searchQueryState.searchQueryUse()

    const activeDealerCategories = computed(() => activeCategories.value.filter((item) => !!item.logo))
    const activeCategoriesWithoutDealers = computed(() => activeCategories.value.filter((item) => !item.logo))
    const hasDealerCategories = computed(() => activeDealerCategories.value.length)
    const hasDefaultFilters = computed(() => activeCategoriesWithoutDealers.value.length || activeTerms.value.length)
    const hasFilters = computed(() => hasDealerCategories.value || hasDefaultFilters.value)

    const removeFilter = (filter) => {
      removeCategory(filter)
      gtmUtil.removeFilter(filter.name)
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeTag(filter.name, currentTags.join(', '))
    }

    const removeSearchTerm = (term) => {
      removeTerm(term)
      gtmUtil.removeFilter(term)
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeTag(term, currentTags.join(', '))
    }

    const removeAllFilters = () => {
      const currentTags = []
      activeCategories.value.forEach((item) => {
        currentTags.push(item.name)
      })
      gtmUtil.removeAllTags(currentTags.join(', '))
      resetAllFilters()
      gtmUtil.removeAllFilters()
    }

    return {
      iconClose,
      activeTerms,
      activeCategories,
      activeDealerCategories,
      activeCategoriesWithoutDealers,
      hasFilters,
      hasDealerCategories,
      hasDefaultFilters,
      translations: computed(() => searchConfiguration.translations),

      removeSearchTerm,
      removeFilter,
      removeAllFilters
    }
  }
}
</script>
