<template>
  <div class="dealer-list__details">
    <strong> {{ dealer.header }}</strong>
    <address>
      <span>{{ streetInfo }}</span><span v-if="dealer.zipCode || dealer.city">,</span>
      {{ dealer.zipCode }} {{ dealer.city }}
    </address>
    <div class="dealer-list__details-contacts">
      <strong class="">{{ model.translations.dealerContactLabel }}</strong>
      <div class="dealer-list__details-contact">
        {{ model.translations.dealerContactTelephoneLabel }} {{ dealer.phoneNumber }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    dealer: {
      type: Object,
      required: true
    }
  },
  computed: {
    streetInfo() {
      const name = this.dealer.streetName.trim()
      const number = this.dealer.houseNumber.trim()

      return number.length > 0 ? `${name} ${number}` : name
    }
  }
}
</script>
