<template>
  <div class="c-recipe-list">
    <RecipeGridCard
      v-for="(item, index) in items"
      :key="index"
      :model="item"
    />
  </div>
</template>

<script>
import RecipeGridCard from '@/components/RecipeGridCard.vue'

export default {
  components: {
    RecipeGridCard
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
