<template>
  <div
    v-click-away="onClickOutside"
    :data-f-element-name="model.elementName"
    data-f-type="textbox"
    class="c-autocomplete"
  >
    <label
      :for="model.elementGuid"
      class="Form__Element__Caption"
    >
      {{ model.label }}
    </label>
    <input
      :id="model.elementGuid"
      :name="model.elementName"
      :value="searchTerm"
      :placeholder="model.elementPlaceholder"
      type="text"
      :class="model.elementValidationCssClasses"
      class="FormTextbox__Input autocomplete-input"
      @focus="onInputFocus"
      @blur="onInputBlur"
      @input="searchTerm = $event.target.value"
    >
    <div v-html="model.elementValidationMessageHtml" />
    <div
      v-if="loading"
      class="c-autocomplete__loader"
    >
      <div class="spinner" />
    </div>

    <!-- Search results -->
    <transition
      enter-active-class="transition-all duration-500 ease-out"
      leave-active-class="transition-all duration-500 ease-out"
      enter-class="max-h-0"
      enter-to-class="max-h-screen"
      leave-class="max-h-screen"
      leave-to-class="max-h-0"
    >
      <ul
        v-if="!loading && searchResults && isDropdownActive"
        class="c-autocomplete__list u-bare-list"
      >
        <li
          v-for="(item, index) in searchResults"
          :key="index"
          class="c-autocomplete__list-item"
          tabindex="0"
          @click="onItemClick(item)"
          @keyup="onItemClick(item)"
        >
          {{ item }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import {
  ref, watch
} from 'vue'
import { directive } from 'vue3-click-away'
import quickSearchComponent from '@/composition/quickSearchComponent'

export default {
  directives: {
    ClickAway: directive
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const errorMessage = ref('')
    const searchTerm = ref('')
    const debouncedInput = ref('')
    const isInputInFocus = ref(false)
    const isDropdownActive = ref(false)
    let timeout = null

    const {
      loading, searchResults, setTerm, resetQuickSearchResults
    } = quickSearchComponent(props.model.apiUrl)

    watch(searchTerm, (val) => {
      if (isInputInFocus.value) {
        if (timeout) clearTimeout(timeout)

        if (val.length < 3) {
          isDropdownActive.value = false
        }

        timeout = setTimeout(() => {
          debouncedInput.value = val

          if (val.length >= 3) {
            isDropdownActive.value = true
            setTerm(val)
          }
        }, 500)
      }
    })

    function onInputBlur() {
      isInputInFocus.value = false
    }

    function onInputFocus() {
      isInputInFocus.value = true
    }

    function onClickOutside() {
      isDropdownActive.value = false
    }

    function onItemClick(value) {
      searchTerm.value = value
      isDropdownActive.value = false
      resetQuickSearchResults()
    }

    return {
      loading,
      isInputInFocus,
      isDropdownActive,
      searchTerm,
      searchResults,
      errorMessage,

      onInputFocus,
      onInputBlur,
      onItemClick,
      onClickOutside
    }
  }
}
</script>
